import $ from 'jquery';

const apLiveSchedules = {
  init: function () {
    let scheduleSelect = $('#ap-live-tw-schedules');

    if (scheduleSelect.length === 0) {
      return;
    }

    scheduleSelect.on('change', function () {
      const selectedSchedule = $(this).val();

      if (selectedSchedule === 'all') {
        $('.ap-tw-schedule').removeClass('tw-hidden');
      } else {
        $('.ap-tw-schedule').addClass('tw-hidden');
        $(`#${selectedSchedule}`).removeClass('tw-hidden');
      }
    })
  },
};
const apSetupCalendars = {
  init() {
    const calendlyURL = 'https://calendly.com/apsetup/ap-setup';

    $('#ap-setup-calendars-button').click(function () {
      let language = $('#ap-setup-calendars').val();
      let url = language === 'english' ? calendlyURL : `${calendlyURL}-${language}`;

      window.open(url, '_blank').focus()
    });
  },
};

$(document).ready(function () {
  apLiveSchedules.init();
  apSetupCalendars.init();
});
